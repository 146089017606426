import React, { useEffect, useState } from 'react';
import coracaoCupom from '../../assets/images/coracao-cupom.png';
import iconCupons from '../../assets/images/icon-cupons.png';
import top from '../../assets/images/iconTop.png';
import * as routes from '../../routes';
import './styles.css';
export function ScrollToTop() {
  const [is_visible, setIs_visible] = useState(false);
  const [openCupom, setOpenCupom] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  useEffect(() => {
    document.addEventListener('scroll', function (e) {
      toggleVisibility();
    });
  }, []);
  function toggleVisibility() {
    if (window.pageYOffset > 100) {
      setIs_visible(true);
    } else {
      setIs_visible(false);
    }
  }

  function getCurrentPageURL() {
    let { pathname } = window.location;
    setCurrentPage(pathname);
  }
  useEffect(() => {
    getCurrentPageURL();
  }, []);
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  function openCupons() {
    setOpenCupom(!openCupom);
  }
  function pageCupons() {
    return (window.location = '/cupons-city');
  }
  return (
    <>
      <div className="scroll-to-top">
        {currentPage !== routes.CUPONS && openCupom && (
          <>
            <div
              className="cupom-open-modal animate__animated animate__pulse animate__infinite animate__slow"
              onClick={() => pageCupons()}
            >
              <img src={coracaoCupom} alt="" />

              <div>
                <h5>
                  <strong>Quer mimos?</strong>
                </h5>
                <p>
                  Clica aqui e te levaremos <br />
                  aos cupons Tropzz
                </p>
              </div>
            </div>
          </>
        )}

        {currentPage !== routes.CUPONS && (
          <div
            className={`button-cupom ${
              !openCupom
                ? 'animate__animated animate__pulse animate__infinite animate__slow'
                : ''
            }`}
          >
            <button onClick={() => openCupons()}>
              <img src={iconCupons} alt="" />
            </button>
          </div>
        )}
      </div>
      <div className="scroll-to-top-button">
        {is_visible && (
          <div onClick={() => scrollToTop()}>
            <img src={top} alt="" />
          </div>
        )}
      </div>
    </>
  );
}
