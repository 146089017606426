import { Carousel } from 'react-bootstrap';
import { ReactComponent as ArrowLeft } from '../../../assets/svg/buttonLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/svg/buttonRight.svg';
import React from 'react';
import franquia from '../../../assets/images/franquia.png';

export function CardFranquia({ index, handleSelect, loja }) {
  return (
    <Carousel
      activeIndex={index}
      onSelect={(e) => handleSelect(e)}
      fade
      indicators={true}
      data-aos="fade-up-left"
      data-aos-duration="2000"
      nextIcon={
        <div className="button-content-tropicalidades">
          <ArrowRight />
        </div>
      }
      prevIcon={
        <div className="button-content-tropicalidades">
          <ArrowLeft />
        </div>
      }
    >
      {loja.map((item, index) => {
        return (
          <Carousel.Item key={index}>
            <div className="container d-md-flex px-5 ">
              <div className="col-12 col-md-5 image-slider">
                <img
                  className="d-block w-100 image-franquia"
                  src={franquia}
                  alt="First slide"
                />
              </div>
              <div className="col-12 col-md-7 m-auto text-slide ">
                <h1>{item.title}</h1>
                <div>
                  <span>{item.description}</span>
                </div>
                <div className="row pt-5">
                  {item.informations.map((info, index) => {
                    return (
                      <div className="col-6 pt-3">
                        <h2>
                          {info.value}{' '}
                          {info.details && (
                            <span className="fs-6">{info.details}</span>
                          )}
                        </h2>
                        <span>{item.title}</span>
                      </div>
                    );
                  })}
                </div>

                {/* <div className="col-12 d-flex pt-5">
                  <div className="col-6">
                    <h2>R$150 mil</h2>
                    <span>Investimento Médio</span>
                  </div>
                  <div className="col-6">
                    <h2>R$40.000,00</h2>
                    <p>Taxa de franquia</p>
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div className="col-6">
                    <h2>30m² a 100m²</h2>
                    <span>Área</span>
                  </div>
                  <div className="col-6">
                    <h2>60 mil</h2>
                    <p>Faturamento médio mensal</p>
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div className="col-6">
                    <h2>18% a 22%</h2>
                    <p>Lucro médio mensal</p>
                  </div>
                  <div className="col-6">
                    <h2>12 a 36</h2>
                    <p>Payback em meses</p>
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div className="col-6">
                    <h2>Royalties 3%</h2>
                    <p>Faturamento brutos</p>
                  </div>
                  <div className="col-6">
                    <h2>
                      2% <span className="fs-6">do faturamento bruto</span>
                    </h2>
                    <p>Taxa de publicidade</p>
                  </div>
                </div> */}
              </div>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
