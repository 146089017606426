import React from 'react';

import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Cupons } from '../pages/Cupons';
import { CuponsCity } from '../pages/CuponsCity';
import { Franquia } from '../pages/Franquia';
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { Tropzz } from '../pages/Tropzz';
export const A_TROPzz = '/a-tropzz';
export const A_FRANQUIA = '/a-franquia';
export const CUPONS = '/cupons-city';
export const INICIO = '/';

export function Routes(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={`${INICIO}`} component={Home} />
        <Route exact path={`${A_TROPzz}/`} component={Tropzz} />
        <Route exact path={`${A_FRANQUIA}/`} component={Franquia} />
        <Route exact path={`${CUPONS}/`} component={CuponsCity} />
        <Route exact path={`/cupons/:citySelected/`} component={Cupons} />
      </Switch>
    </BrowserRouter>
  );
}
