import axios from "axios";
let apiU = "https://us-central1-paineltropzz.cloudfunctions.net/";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // apiU = "http://localhost:3333/paineltropzz/us-central1/";
  apiU = "https://us-central1-paineltropzz.cloudfunctions.net/";
} else {
  // apiU = "http://localhost:5000/paineltropzz/us-central1/";
  apiU = "https://us-central1-paineltropzz.cloudfunctions.net/";
}

const api = axios.create({
  baseURL: apiU,
});

export const apiURL = apiU;
export default api;
