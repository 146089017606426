import React from 'react';
import '../Cupons/cupons.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import cupom from '../../assets/svg/cupom.svg';

import { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import _ from 'underscore';
import { Navbar } from '../../components/Navbar';
import api from '../../services/api';

export function CuponsCity() {
  const [index, setIndex] = useState(0);
  const [dataState, setData] = useState({});
  const [cupons, setCupons] = useState({});
  const [city, setCity] = useState('');
  const [texts, setTexts] = useState('');
  const [voucher, setVoucher] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('');
  const [voucherSelected, setVoucherSelected] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const array = [
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Adfgdfgdfgião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentagggmodelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de nesdfsdfine e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
  ];
  const getId = (index) => `${index + 1}`;
  const getItems = () =>
    Array(7)
      .fill(0)
      .map((_, ind) => ({
        id: getId(ind),
        title: array[ind].title,
        text: array[ind].text,
      }));
  const handleItemClick = (itemId) => () => setSelected(itemId);
  const [items] = useState(getItems);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  useEffect(() => {
    getCupons();
  }, []);
  useEffect(() => {
    getTexts();
  }, []);

  async function getTexts() {
    let parameters = {
      searchFunctionality: 'getTexts',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    const questions = res.data.data.questions;
    const organizedQuestionsByAlphabet = questions.sort((a, b) => {
      return a.cityAndState.localeCompare(b.cityAndState);
    });
    const newData = {
      ...res.data.data,
      questions: organizedQuestionsByAlphabet,
    };
    setTexts(newData);
  }

  async function getCupons() {
    let parameters = {
      searchFunctionality: 'getCupons',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);

    setCupons(res.data.data);
  }

  function handleSelectCity(option) {
    setCity(option);
  }
  function normalizeLowerCase(str) {
    return str
      .replace(/ /g, '_')
      .replace('?', '')
      .normalize('NFD') // Formato de Normalização Canônico de Decomposição.
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  function handleSetCity(e) {
    // const benefit = await getBenefit(city.data);
    // const teste = _.filter(benefit.data.data, (voucher) => {
    //   console.log(benefit.data.data);
    //   if (voucher.benefitCategory.label === city.label) {
    //     return voucher;
    //   }
    // });
    // console.log(
    //   '🚀 ~ file: index.jsx ~ line 158 ~ teste ~ teste',
    //   normalizeLowerCase(city.value),
    // );
    // setVoucher(teste);
  }
  function openModalCupom(voucher, index) {
    setVoucherSelected(voucher);
    setCuponRescue(voucher);
    // ModalConfirm();
    onOpenModal();
  }

  async function getBenefit() {
    let parameters = {
      searchFunctionality: 'getBenefit',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    return res;
  }
  async function setCuponRescue(voucher) {
    let parameters = {
      searchFunctionality: 'setCuponRescue',
      userType: 'site',
      voucher,
    };
    const res = await api.post(`/site`, parameters);

    return res;
  }
  const onOpenModal = () => setModalIsOpen(true);
  const onCloseModal = () => setModalIsOpen(false);
  function ModalConfirm(index) {
    return (
      <Modal
        open={modalIsOpen}
        onClose={onCloseModal}
        classNames={{ modal: 'modal-cupons' }}
        backdrop="static"
        keyboard={false}
      >
        <div className="container">
          <div className="col-12    voucher-container">
            <div className="col-12">
              <div className="col-12 col-md-10 m-auto  text-center">
                <img
                  src={voucherSelected.benefitImage}
                  alt="coqueiro"
                  width="50%"
                />
              </div>
              <div>
                <h3>{voucherSelected.benefitName}</h3>
              </div>
              <div className="benefit-description">
                <span>{voucherSelected.benefitDescription}</span>
              </div>

              <div className="col-12 col-md-2 d-flex align-items-center">
                <span>R${voucherSelected.benefitValue}</span>
              </div>
              <br />
              <div className="benefit-description backgroud-purple color-yellow">
                <div>
                  <span>{voucherSelected.benefitCode}</span>
                </div>
              </div>
              <br />
              <div className="">
                <p>
                  Promoção válida por tempo determinado ou enquanto durarem os
                  estoques.Promoção não cumulativa. Os preços dos produtos podem
                  variar de acordo com região e/ou restaurantes. Promoção válida
                  somente nas lojas Tropzz participantes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <Navbar />
      <div>
        {console.log(voucher.length)}
        <section
          className={`background-roxo d-md-flex justify-content-center m-auto p-5 cupons align-content-center min-height-100
          `}
          style={{ minHeight: '100vh' }}
        >
          <div className="m-auto">
            <div className="container d-flex col-12 m-auto p-t-8">
              <div className="col-12  col-md-6 align-self-center p-5">
                <h1 className="color-white">Bem vindo à página dos mimos</h1>
                <h4 className="color-white">
                  Para prosseguir, digite sua localidade e escolha uma de nossas
                  unidades
                </h4>
                <br />
                <br />

                <Select
                  className=""
                  placeholder="Selecione a cidade"
                  options={
                    texts.questions &&
                    texts.questions.map((address) => ({
                      value: address.cityAndState,
                      label: address.cityAndState,
                      data: address,
                    }))
                  }
                  onChange={(option) => handleSelectCity(option)}
                />
                <br />
                <br />
                <div className="button-cupom">
                  <div
                    type="button"
                    className="about-us"
                    // onClick={(e) => handleSetCity(e)}
                  >
                    <Link
                      to={{
                        pathname: `/cupons/${
                          city && city.value && city.value
                        }/`,
                        state: { from: city },
                      }}
                    >
                      <span>Continuar</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 p-5 d-none d-md-flex">
                <img src={cupom} alt="" />
              </div>
            </div>
            <div className="col-12 pt-4">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                // autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={['tablet', 'mobile']}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {_.map(voucher, (voucher, index) => {
                  return (
                    <>
                      <div
                        className="col-12 p-right-10"
                        onClick={(e) => openModalCupom(voucher, index)}
                        key={index}
                      >
                        <div className="col-12 m-4 p-4  voucher-container">
                          <div className="col-12 d-md-flex image-cupom">
                            <div className="col-12 col-md-10 m-auto p-md-2 text-center">
                              <img
                                src={voucher.benefitImage}
                                alt="coqueiro"
                                width="100%"
                              />
                            </div>

                            <div className="col-12 col-md-2 d-flex align-items-center mb-4 mb-md-0">
                              <div className="backgroud-blue">
                                <span>R${voucher.benefitValue}</span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h3>{voucher.benefitName}</h3>
                          </div>
                          <div className="benefit-description">
                            <span>{voucher.benefitDescription}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </section>
      </div>
      {ModalConfirm()}
    </>
  );
}
