import React, { useEffect, useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { isMobile } from 'react-device-detect';
import _ from 'underscore';
import { ReactComponent as ArrowLeft } from '../../assets/svg/buttonLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/buttonRight.svg';
import api from '../../services/api';
import './styles.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);
export function CarouselFirst() {
  const [banner, setBanner] = useState();

  useEffect(() => {
    getBanners();
  }, []);

  async function getBanners() {
    let parameters = {
      searchFunctionality: 'loadBanner',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    setBanner(res.data.data);
  }

  return (
    <div class="carousel-first">
      <div
        className="row"
        style={{
          // paddingBottom: '2vh',
          marginLeft: '-25px',
          marginRight: '-25px',
        }}
      >
        {isMobile && (
          <AutoplaySlider
            play={true}
            interval={5000}
            cancelOnInteraction={true}
            mobileTouch={true}
            infinite={true}
            organicArrows={false}
            bullets={true}
            touchEnabled={true}
            touch={true}
            // buttonContentRight={
            //   <div className="button-content-first-slide">
            //     <ArrowRight />
            //   </div>
            // }
            // buttonContentLeft={
            //   <div className="button-content-first-slide">
            //     <ArrowLeft />
            //   </div>
            // }
            // fillParent={true}
            animation="cubeAnimation"
          >
            {_.map(banner && banner.bannerImageMobile, (banner, i) => {
              return (
                <div
                  key={i}
                  className="bannercarousel bannercarouselMobile"
                  style={{
                    padding: '0px',
                    width: '100%',
                    backgroundImage: `url(${banner.url})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                ></div>
              );
            })}
          </AutoplaySlider>
        )}
        {!isMobile && (
          <AutoplaySlider
            play={true}
            interval={5000}
            cancelOnInteraction={true}
            mobileTouch={false}
            infinite={true}
            organicArrows={false}
            bullets={false}
            touchEnabled={true}
            touch={true}
            buttonContentRight={
              <div className="button-content-first-slide">
                <ArrowRight />
              </div>
            }
            buttonContentLeft={
              <div className="button-content-first-slide">
                <ArrowLeft />
              </div>
            }
            // fillParent={true}
            animation="cubeAnimation"
          >
            {banner &&
              _.map(banner.bannerImage, (banner, i) => {
                return (
                  <div
                    key={i}
                    className="bannercarousel "
                    style={{
                      padding: '0px',
                      width: '100%',
                      backgroundImage: `url(${banner.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  ></div>
                );
              })}
          </AutoplaySlider>
        )}
      </div>
    </div>
  );
}
