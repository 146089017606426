import React, { useRef } from 'react';
import '../Cupons/cupons.css';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'underscore';
import { Navbar } from '../../components/Navbar';
import api from '../../services/api';

export function Cupons() {
  const { citySelected } = useParams();
  const location = useLocation();
  const { from } = location.state;
  console.log('🚀 ~ file: index.jsx ~ line 21 ~ Cupons ~ from', from);
  const [index, setIndex] = useState(0);
  const [dataState, setData] = useState({});
  const [cupons, setCupons] = useState({});

  const [city, setCity] = useState(from);
  const [texts, setTexts] = useState('');
  const [voucher, setVoucher] = useState([]);
  const [selected, setSelected] = useState('');
  const [voucherSelected, setVoucherSelected] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const array = [
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Adfgdfgdfgião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentagggmodelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de nesdfsdfine e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
  ];
  const getId = (index) => `${index + 1}`;
  const getItems = () =>
    Array(7)
      .fill(0)
      .map((_, ind) => ({
        id: getId(ind),
        title: array[ind].title,
        text: array[ind].text,
      }));
  const handleItemClick = (itemId) => () => setSelected(itemId);
  const [items] = useState(getItems);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  useEffect(() => {
    getCupons();
  }, []);
  useEffect(() => {
    handleSetCity();
  }, []);
  useEffect(() => {
    getTexts();
  }, []);

  async function getTexts() {
    let parameters = {
      searchFunctionality: 'getTexts',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    setTexts(res.data.data);
  }

  async function getCupons() {
    let parameters = {
      searchFunctionality: 'getCupons',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);

    setCupons(res.data.data);
  }
  async function handleSetCity() {
    const benefit = await getBenefit(city.data);

    const teste = _.filter(benefit.data.data, (voucher) => {
      if (voucher.benefitCategory.label === city.label) {
        return voucher;
      }
    });

    setVoucher(teste);
  }
  const carouselRef = useRef(null);

  // useEffect(() => {
  //   // Obter todos os itens do carrossel
  //   const items = carouselRef?.current?.state?.children;

  //   const maxHeight = Math.max(...items?.map((item) => item.clientHeight));

  //   items.forEach((item) => {
  //     item.style.height = `${maxHeight}px`;
  //   });
  // }, [voucher]);

  // async function handleSetCity() {
  //   const benefit = await getBenefit(city.data);

  //   const teste = _.filter(benefit.data.data, (voucher) => {
  //     console.log(benefit.data.data);
  //     if (voucher.benefitCategory.label === city.label) {
  //       return voucher;
  //     }
  //   });

  //   setVoucher(teste);
  // }
  function openModalCupom(voucher, index) {
    setVoucherSelected(voucher);
    setCuponRescue(voucher);
    // ModalConfirm();
    onOpenModal();
  }

  async function getBenefit() {
    let parameters = {
      searchFunctionality: 'getBenefit',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    return res;
  }
  async function setCuponRescue(voucher) {
    let parameters = {
      searchFunctionality: 'setCuponRescue',
      userType: 'site',
      voucher,
    };
    const res = await api.post(`/site`, parameters);

    return res;
  }
  const onOpenModal = () => setModalIsOpen(true);
  const onCloseModal = () => setModalIsOpen(false);
  function ModalConfirm(index) {
    return (
      <Modal
        open={modalIsOpen}
        onClose={onCloseModal}
        classNames={{ modal: 'modal-cupons' }}
        backdrop="static"
        keyboard={false}
      >
        <div className="container">
          <div className="col-12    voucher-container">
            <div className="col-12">
              <div className="col-12 col-md-10 m-auto  text-center">
                <img
                  src={voucherSelected.benefitImage}
                  alt="coqueiro"
                  width="50%"
                />
              </div>
              <div>
                <h3>{voucherSelected.benefitName}</h3>
              </div>
              <div className="benefit-description">
                <span>{voucherSelected.benefitDescription}</span>
              </div>

              <div className="col-12 col-md-2 d-flex align-items-center">
                <span>R${voucherSelected.benefitValue}</span>
              </div>
              <br />
              <div className="benefit-description backgroud-purple color-yellow">
                <div>
                  <span>{voucherSelected.benefitCode}</span>
                </div>
              </div>
              <br />
              <div className="">
                <p>
                  Promoção válida por tempo determinado ou enquanto durarem os
                  estoques.Promoção não cumulativa. Os preços dos produtos podem
                  variar de acordo com região e/ou restaurantes. Promoção válida
                  somente nas lojas Tropzz participantes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <Navbar />
      <div>
        <section
          className={`background-roxo p-5 cupons m-auto align-content-center `}
          style={{ minHeight: '100vh' }}
        >
          <div className="container d-flex col-12 m-auto p-t-8">
            <div className="col-12  col-md-6 align-self-center p-md-5">
              <h1 className="color-yellow">E vamos de mimos!</h1>
              <h4 className="color-white">
                Pra utilizar, é só clicar no cupom desejado e apresentá-lo no
                ato do pedido.
              </h4>
            </div>
          </div>
          <div className="col-12 pt-4">
            <Carousel
              swipeable={true}
              draggable={true}
              ref={carouselRef}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={false}
              autoPlay={false}
              // autoPlaySpeed={1000}
              keyBoardControl={true}
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              // deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
            >
              {_.map(voucher, (voucher, index) => {
                return (
                  <>
                    <div
                      className="col-12 pe-md-5 p-2 voucher-container-each"
                      style={{ cursor: 'pointer' }}
                      onClick={(e) => openModalCupom(voucher, index)}
                      key={index}
                    >
                      <div className="col-12 m-md-4  p-4  voucher-container">
                        <div className="col-12 d-md-flex image-cupom">
                          <div className="col-12 col-md-9 m-auto p-md-2 text-center">
                            <img
                              src={voucher.benefitImage}
                              alt="coqueiro"
                              width="100%"
                            />
                          </div>

                          <div className="position-relative col-12 col-md-3 d-flex align-items-center mb-4 mb-md-0 w-100">
                            <div className="backgroud-blue">
                              <span>R${voucher.benefitValue}</span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h3>{voucher.benefitName}</h3>
                        </div>
                        <div className="benefit-description">
                          <span>{voucher.benefitDescription}</span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Carousel>
          </div>
        </section>
      </div>
      {ModalConfirm()}
    </>
  );
}
