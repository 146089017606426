import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-responsive-modal";
import _ from "underscore";
import folhaModal from "../../assets/images/folha-modal.png";
import api from "../../services/api";
import ToggleSwitch from "../ToggleSwitch";
import "./styles.css";

import firebase from "firebase";
import { useEffect } from "react";

export function Form() {
  let [newsletter, setNewsletter] = useState(true);
  const [index, setIndex] = useState(0);
  const [dataState, setData] = useState({});
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [selected, setSelected] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenFaleConosco, setModalIsOpenFaleConosco] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onNewsletterChange = (checked) => {
    setNewsletter(checked);
  };
  function getCityList(estado) {
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`,
      )
      .then((city) => {
        setCity(city.data);
      })
      .catch((error) => {
        console.log("getCityList error:", error);
      });
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "state" && value) {
      getCityList(value);

      return;
    }
  };
  useEffect(() => {
    getStates();
  }, []);

  function getStates(e) {
    axios
      .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
      .then((states) => {
        const sortedStates = _.sortBy(states.data, "nome");

        setState(sortedStates);
      })
      .catch((error) => {
        console.log("getStateList error:", error);
      });
  }

  function handleUploadSuccess(file, filename, fileIndex, ref, section) {
    // this.setState({ avatar: filename, progress: 100, isUploading: false });

    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref("Curriculo/")
          .child(filename)
          .getDownloadURL()
          .then(async (url) => {
            setData((prevState) => ({
              ...prevState,
              curriculo: url,
            }));
          });
      });

    console.log(dataState);
  }

  const onOpenModal = () => setModalIsOpen(true);
  const onCloseModal = () => setModalIsOpen(false);
  const onOpenModalFaleConosco = () => setModalIsOpenFaleConosco(true);
  const onCloseModalFaleConosco = () => setModalIsOpenFaleConosco(false);
  async function sendForm(data) {
    if (!dataState) {
      return;
    }

    let parameters = {
      searchFunctionality: "sendFormFaleConosco",
      userType: "site",
      data: dataState,
    };
    let res = await api.post("/site", parameters);
    console.log("ressss", res);
    if (res.data.success) {
      setModalIsOpenFaleConosco(true);
      ModalConfirmFormFaleConosco();
      setData({});
    } else {
    }
  }
  async function sendFormSejaTropzz(data) {
    console.log("aaaaaaa", data);
    data.curriculo = dataState.curriculo;
    let parameters = {
      searchFunctionality: "sendFormSejaTropzz",
      userType: "site",
      data: data,
    };

    let res = await api.post("/site", parameters);
    if (!res.data.success) {
    } else {
      onOpenModal();
      ModalConfirm();
      setData({});
    }
  }

  function ModalConfirm() {
    return (
      <Modal
        open={modalIsOpen}
        onClose={onCloseModal}
        classNames={{ modal: "custom-modal-confirm" }}

        // classNames={{ modal: 'custom-modal' }}
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="text-center mb-5">
              <img src={folhaModal} alt="" className="image-modal-confirm" />
            </div>
            <div className="color-purple font-15 font-book text-center">
              <span className="oq-card-title">Cadastro enviado</span>
              <p>
                Em breve entraremos em contato com você. Para mais informações,
                basta entrar em contato através do nosso whatsapp abaixo.
              </p>
            </div>
            <div className="col-12 m-auto text-center py-3">
              <div
                type="button "
                className="confirm-button-modal m-auto  animation-hover  "
                onClick={onCloseModal}
              >
                <span>Beleza!</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function ModalConfirmFormFaleConosco() {
    return (
      <Modal
        open={modalIsOpenFaleConosco}
        onClose={onCloseModalFaleConosco}
        classNames={{ modal: "custom-modal-confirm" }}

        // classNames={{ modal: 'custom-modal' }}
      >
        <div className="container-fluid">
          <div className="row ">
            <div className="text-center mb-5">
              <img src={folhaModal} alt="" className="image-modal-confirm" />
            </div>
            <div className="color-purple font-15 font-book text-center">
              <span className="oq-card-title">Cadastro enviado</span>
              <p>
                Em breve entraremos em contato com você. Para mais informações,
                basta entrar em contato através do nosso whatsapp abaixo.
              </p>
            </div>
            <div className="col-12 m-auto text-center py-3">
              <div
                type="button "
                className="confirm-button-modal m-auto  animation-hover  "
                onClick={onCloseModalFaleConosco}
              >
                <span>Beleza!</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function handleUserImage(e, ref, fileIndex, section) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "") +
      "." +
      name.split(".")[1];

    if (size < 2000000) {
      console.log("entrou aqui ");
      setData((prevState) => ({
        ...prevState,
        fileIndex: randomizeName,
      }));

      handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
        section,
      );
    } else {
      return alert("TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!");
    }
  }
  return (
    <>
      <ToggleSwitch
        id="newsletter"
        checked={newsletter}
        onChange={onNewsletterChange}
      />
      <div className="col-12">
        {newsletter && (
          <>
            <div className="background-white">
              <div className="container col-12 d-md-flex">
                <div className="col-12 col-md-12 ">
                  {/* <form onSubmit={() => handleSubmit(sendForm)}> */}
                  <div className="col-12 col-md-12 ">
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.name ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          // required={true}
                          // name="name"
                          // ref={register({ required: true, maxLength: 20 })}
                          value={dataState && dataState.name}
                          {...register("name", { required: true })}
                        />
                        {errors.name && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span
                          className={`input__label ${
                            errors.name ? "form-error-input__label" : ""
                          }`}
                        >
                          Nome Completo
                        </span>
                        {errors.name && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.email ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          // required={true}
                          name="email"
                          value={dataState && dataState.email}
                          {...register("email", {
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                        {errors.email && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span
                          className={`input__label ${
                            errors.email ? "form-error-input__label" : ""
                          }`}
                        >
                          Email
                        </span>
                        {errors.email && (
                          <span className="form-error">
                            Por favor, verifique se seu e-mail está correto.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.telefone ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          // required={true}
                          name="telefone"
                          value={dataState && dataState.telefone}
                          {...register("telefone", {
                            required: newsletter ? true : false,
                            onChange: (e) => handleInputChange(e),
                          })}
                        />
                        {errors.telefone && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span
                          className={`input__label ${
                            errors.telefone ? "form-error-input__label" : ""
                          }`}
                        >
                          Telefone
                        </span>
                        {errors.telefone && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="mt-3">
                      <label className="input">
                        <select
                          className={`input__field ${
                            errors.comoConheceu ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          // required={true}
                          name="comoConheceu"
                          value={dataState && dataState.comoConheceu}
                          {...register("comoConheceu", {
                            required: true,
                            onChange: (e) => handleInputChange(e),
                          })}
                        >
                          <option value="">Selecione uma opção</option>
                          <option value="redes-sociais">Redes Sociais</option>
                          <option value="google">Google</option>
                          <option value="amigos">Amigos</option>
                          <option value="loja">Já visitei uma loja</option>
                          <option value="outros">Outros</option>
                        </select>
                        {errors.comoConheceu && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span
                          className={`input__label ${
                            errors.comoConheceu ? "form-error-input__label" : ""
                          }`}
                        >
                          Como conheceu a Tropzz?
                        </span>
                        {errors.comoConheceu && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="col-12 col-md-12 d-flex">
                      <div className="col-4 p-right-5">
                        <div className="mt-3">
                          <label className="input">
                            <select
                              className={`input__field ${
                                errors.state ? "form-error-input" : ""
                              }`}
                              type="text"
                              placeholder=" "
                              // onChange={(e) => handleInputChange(e)}
                              // required={true}
                              name="state"
                              value={dataState && dataState.state}
                              {...register("state", {
                                required: newsletter ? true : false,
                                onChange: (e) => handleInputChange(e),
                              })}
                            >
                              <option value="">UF</option>
                              {state &&
                                state.map((state) => {
                                  return (
                                    <option value={state.sigla}>
                                      {state.sigla}
                                    </option>
                                  );
                                })}
                            </select>

                            {/* <span className="input__label"></span> */}
                            {errors.state && (
                              <span className="form-error">
                                Esse campo é obrigatório.
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="mt-3">
                          <label className="input">
                            <select
                              className={`input__field ${
                                errors.city ? "form-error-input" : ""
                              }`}
                              type="text"
                              placeholder=" "
                              onChange={(e) => handleInputChange(e)}
                              // required={true}
                              name="city"
                              value={dataState && dataState.city}
                              {...register("city", {
                                required: newsletter ? true : false,
                              })}
                            >
                              <option value="">Cidade</option>
                              {city &&
                                city.map((city) => {
                                  return (
                                    <option value={city.nome}>
                                      {city.nome}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.city && (
                              <i className="fa fa-exclamation icon icon-validate-input"></i>
                            )}
                            <span className="input__label"></span>
                            {errors.city && (
                              <span className="form-error">
                                Esse campo é obrigatório.
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.complement ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          // required={true}
                          name="complement"
                          value={dataState && dataState.complement}
                          {...register("complement", { required: true })}
                        />

                        <span
                          className={`input__label ${
                            errors.complement ? "form-error-input__label" : ""
                          }`}
                        >
                          Algo mais a ser dito?
                        </span>
                        {errors.complement && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="d-flex justify-content-end pt-3">
                      <div
                        type="submit"
                        className="about-us"
                        onClick={handleSubmit(sendForm)}
                      >
                        <span className="letter-spacing">ENVIAR</span>
                      </div>
                    </div>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </>
        )}
        {!newsletter && (
          <>
            <div className="background-white">
              <div className="container col-12 d-md-flex">
                <div className="col-12 col-md-12 ">
                  <div className="col-12 col-md-12 ">
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.name ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          name="name"
                          value={dataState && dataState.name}
                          {...register("name", { required: true })}
                        />
                        <span
                          className={`input__label ${
                            errors.name ? "form-error-input__label" : ""
                          }`}
                        >
                          Nome Completo
                        </span>
                        {errors.name && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.email ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          required={true}
                          name="email"
                          value={dataState && dataState.email}
                          {...register("email", {
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                        <span
                          className={`input__label ${
                            errors.email ? "form-error-input__label" : ""
                          }`}
                        >
                          Email
                        </span>
                        {errors.email && (
                          <span className="form-error">
                            Por favor, verifique se seu e-mail está correto.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.telefone ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          // required={true}
                          name="telefone"
                          value={dataState && dataState.telefone}
                          {...register("telefone", {
                            required: true,
                            onChange: (e) => handleInputChange(e),
                          })}
                        />
                        {errors.telefone && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span
                          className={`input__label ${
                            errors.telefone ? "form-error-input__label" : ""
                          }`}
                        >
                          Telefone
                        </span>
                        {errors.telefone && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.newCurriculo ? "form-error-input" : ""
                          }`}
                          type="file"
                          accept="pdf/*"
                          id="newCurriculo"
                          name="newCurriculo"
                          {...register("newCurriculo", { required: true })}
                          onChange={(event) => {
                            handleUserImage(
                              event,
                              `Curriculo/`,
                              "newCurriculo",
                              "curriculo",
                            );
                          }}
                        />
                        <span className="input__label">Currículo</span>
                        {errors.newCurriculo && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-md-12 d-flex">
                      <div className="col-4 p-right-5">
                        <div className="mt-3">
                          <label className="input">
                            <select
                              className={`input__field ${
                                errors.state ? "form-error-input" : ""
                              }`}
                              type="text"
                              placeholder=" "
                              // onChange={(e) => handleInputChange(e)}
                              // required={true}
                              name="state"
                              value={dataState && dataState.state}
                              {...register("state", {
                                required: true,
                                onChange: (e) => handleInputChange(e),
                              })}
                            >
                              <option value="">UF</option>
                              {state &&
                                state.map((state) => {
                                  return (
                                    <option value={state.sigla}>
                                      {state.sigla}
                                    </option>
                                  );
                                })}
                            </select>

                            {/* <span className="input__label"></span> */}
                            {errors.state && (
                              <span className="form-error">
                                Esse campo é obrigatório.
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="mt-3">
                          <label className="input">
                            <select
                              className={`input__field ${
                                errors.city ? "form-error-input" : ""
                              }`}
                              type="text"
                              placeholder=" "
                              onChange={(e) => handleInputChange(e)}
                              // required={true}
                              name="city"
                              value={dataState && dataState.city}
                              {...register("city", {
                                required: true,
                              })}
                            >
                              <option value="">Cidade</option>
                              {city &&
                                city.map((city) => {
                                  return (
                                    <option value={city.nome}>
                                      {city.nome}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.city && (
                              <i className="fa fa-exclamation icon icon-validate-input"></i>
                            )}
                            <span className="input__label"></span>
                            {errors.city && (
                              <span className="form-error">
                                Esse campo é obrigatório.
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <select
                          className={`input__field ${
                            errors.comoConheceu ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          required={true}
                          name="comoConheceu"
                          value={dataState && dataState.comoConheceu}
                          {...register("comoConheceu", { required: true })}
                        >
                          <option value="">Selecione uma opção</option>
                          <option value="Atendente">Atendente</option>
                          <option value="Administrativo">Administrativo</option>
                          <option value="Financeiro">Financeiro</option>
                          <option value="outros">Outros</option>
                        </select>
                        <span
                          className={`input__label ${
                            errors.comoConheceu ? "form-error-input__label" : ""
                          }`}
                        >
                          Cargo
                        </span>
                        {errors.comoConheceu && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.complement ? "form-error-input" : ""
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          required={true}
                          name="complement"
                          value={dataState && dataState.complement}
                          {...register("complement", { required: true })}
                        />
                        <span
                          className={`input__label ${
                            errors.complement ? "form-error-input__label" : ""
                          }`}
                        >
                          Algo mais a ser dito?
                        </span>
                        {errors.complement && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="d-flex justify-content-end pt-3">
                      <div
                        type="button"
                        className="about-us"
                        onClick={handleSubmit(sendFormSejaTropzz)}
                      >
                        <span>ENVIAR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {ModalConfirm()}
        {ModalConfirmFormFaleConosco()}
      </div>
    </>
  );
}
