import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/svg/instagram.svg";
import * as routes from "../../routes";
import "./styles.css";
export function Navbar() {
  const [currentPage, setCurrentPage] = useState("");
  function getIsActive(route) {
    if (String(currentPage) === route) {
      return currentPage;
    }
    // return String(currentPage).includes(route);
  }
  function getCurrentPageURL() {
    let { pathname } = window.location;
    setCurrentPage(pathname);
  }
  useEffect(() => {
    getCurrentPageURL();
  }, []);

  return (
    <>
      <nav className=" navbar navbar-fixed-top navbar-expand-lg navbar-light logo-navbar justify-content-between p-0">
        <div className="col-2 logo-navbar text-center">
          <img src={logo} alt="logo" />
        </div>
        <div className="backgroud-white icon-navbar">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          className=" menu-items collapse navbar-collapse backgroud-white "
          id="navbarNavDropdown"
        >
          <ul className={`navbar-nav `}>
            <li className={`nav-item icons-navbar `}>
              <Link
                to={routes.INICIO}
                className={`nav-link ${
                  getIsActive(routes.INICIO) ? "active" : ""
                }`}
              >
                Início
              </Link>
            </li>
            <li className="nav-item icons-navbar">
              <Link
                to={routes.A_TROPzz}
                className={`nav-link ${
                  getIsActive(routes.A_TROPzz) ? "active" : ""
                }`}
              >
                A Tropzz
              </Link>
            </li>
            <li className="nav-item icons-navbar">
              <a
                className={`nav-link  ${
                  getIsActive(routes.A_FRANQUIA) ? "active" : ""
                }`}
                href={routes.A_FRANQUIA}
              >
                Franquia
              </a>
            </li>
            <li className="nav-item icons-navbar">
              <a className={`nav-link `} href={`${routes.INICIO}#address`}>
                Lojas
              </a>
            </li>
            <li className="nav-item icons-navbar">
              <a className="nav-link" href={`${routes.INICIO}#contato`}>
                Contato
              </a>
            </li>
            <li className="nav-item icons-navbar">
              <a
                className="nav-link"
                href="https://linktr.ee/marcatropzz"
                target="_blank"
                rel="noreferrer"
              >
                Delivery
              </a>
            </li>
            <li className="nav-item icons-navbar">
              <a className="nav-link" href={routes.CUPONS}>
                Cupons
              </a>
            </li>
            <li className="nav-item nav-mobile">
              <p className="nav-link">contato@tropzz.com.br</p>
            </li>
            <li className="nav-item nav-mobile">
              <div className="d-flex social-icons justify-content-center">
                <a
                  className="nav-link"
                  href="https://www.facebook.com/tropzzoficial/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <div>
                    <Facebook />
                  </div>
                </a>
                <a
                  className="nav-link"
                  href="https://www.instagram.com/tropzzoficial/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <div>
                    <Instagram />
                  </div>
                </a>
              </div>
            </li>
          </ul>
          <div className="nav-item col-5 d-flex area-franqueado">
            {/* <div className="area-franquia m-auto">
                <FontAwesomeIcon icon={faUser} />
                <a className="" href="/login">
                
                <span className="">Área Franqueado</span>
              </a>
              </div> */}
            <div className="d-sm-flex social-icons d-none">
              <a
                className="nav-link"
                href="https://www.facebook.com/tropzzoficial/"
                target={"_blank"}
                rel="noreferrer"
              >
                <div>
                  <Facebook />
                </div>
              </a>
              <div>
                <a
                  className="nav-link"
                  href={`https://www.instagram.com/tropzzoficial/`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
