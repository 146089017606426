import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import loja from '../../assets/images/loja.png';
import delivery from '../../assets/svg/delivery.svg';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';
import mapa from '../../assets/svg/mapa.svg';
import { ReactComponent as Whatsapp } from '../../assets/svg/whatsapp.svg';
import './adressInput.css';

import api from '../../services/api';

export function AddressInput(props) {
  const [city, setCity] = useState({});
  const [address, setAddress] = useState([]);
  const [phoneWhithoutMask, setPhoneWhithoutMask] = useState([]);

  useEffect(() => {
    getAddress();
  }, []);

  async function getAddress() {
    let parameters = {
      searchFunctionality: 'getAddress',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);

    setAddress(
      res.data.data.questions.sort((a, b) =>
        a.cityAndState.localeCompare(b.cityAndState),
      ),
    );
  }
  function handleSetCity(option) {
    setCity(option);
  }
  function removeMask(str) {
    return (str = str.replace(/\D/g, ''));
  }

  return (
    <div>
      <div className="col-12 p-3  card-div" id="card-div">
        <div class="card-address" id="card-address">
          <h3 className="input__name d-md-grid d-lg-grid d-none">
            Onde cê mora?
          </h3>
          <p className="input__name2  d-md-grid d-lg-grid d-none">
            Encontre um paraíso tropical mais próximo.
          </p>
          <label className="input">
            <Select
              className=""
              defaultValue={{
                value: 'Sua cidade...',
                label: 'Sua cidade...',
              }}
              options={address.map((address) => ({
                value: address.cityAndState,
                label: address.cityAndState,
                data: address,
              }))}
              onChange={(option) => handleSetCity(option)}
            />
          </label>
        </div>
        <div className="image-loja d-grid d-md-none d-lg-none">
          <img src={loja} alt="" width="100%" />
        </div>
        <div className="car_details  d-flex p-md-4 p-lg-4 z-3">
          {city.value && (
            <>
              <div>
                <div className="d-flex">
                  <div className="col-6 px-2 container">
                    <div>
                      <span>Aqui em {city.label}</span>
                      <br />
                      {city.value && (
                        <>
                          <span>{city.data.address}</span>
                          <p>{city.data.phone}</p>
                          <div className="d-flex">
                            <div className="img">
                              <img src={mapa} alt="" />
                            </div>

                            <a
                              className="no-text-decoration"
                              href={city.data.maps}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Abrir no mapa
                            </a>
                          </div>
                          <br />
                          <div className="d-flex mb-3">
                            <div className="img">
                              <img src={delivery} alt="" />
                            </div>
                            <a
                              className="no-text-decoration"
                              href="https://linktr.ee/marcatropzz"
                              target="_bllank"
                            >
                              Ir para Delivery
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-6 px-2 d-flex schedule ">
                    <div>
                      <div className="mb-3">
                        <p>
                          <strong>Seg - Sex</strong>
                        </p>
                        <p>{city.data.hour1}</p>

                        <p>
                          <strong>Sab - Dom</strong>
                        </p>
                        <p>{city.data.hour2}</p>
                      </div>
                      <div className="d-flex">
                        <div className="img px-1 icon-social">
                          <a
                            href={city.data.facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Facebook />
                          </a>
                        </div>
                        <div className="img px-1 icon-social">
                          <a
                            href={city.data.instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Instagram />
                          </a>
                        </div>
                        <div className="img px-1 icon-social">
                          {/* <a href={city.data.whatsapp}> */}
                          <a
                            href={`https://api.whatsapp.com/send?phone=55${removeMask(
                              city.data.whatsapp,
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Whatsapp />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="images-instagram">
                  {city.data.instagramImages &&
                    city.data.instagramImages.map((image, index) => {
                      return (
                        <img
                          className="animation-hover-instagram"
                          src={image}
                          alt=""
                          key={index}
                        />
                      );
                    })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
