import AOS from 'aos';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import _ from 'underscore';
import tropzzLogo from '../../assets/images/XMLID 2.png';
import aFranquia from '../../assets/images/a-franquia.png';
import check from '../../assets/images/check.png';
import folhaModal from '../../assets/images/folha-modal.png';
import mochila from '../../assets/images/mochila.png';
import { ReactComponent as FirstFranqueado } from '../../assets/svg/1-franqueado.svg';
import { ReactComponent as SecondtFranqueado } from '../../assets/svg/2-franqueado.svg';
import { ReactComponent as ThirdFranqueado } from '../../assets/svg/3-franqueado.svg';
import { ReactComponent as FourFranqueado } from '../../assets/svg/4-franqueado.svg';
import { ReactComponent as FiveFranqueado } from '../../assets/svg/5-franqueado.svg';
import { ReactComponent as SixFranqueado } from '../../assets/svg/6-franqueado.svg';
import { LeftArrow, RightArrow } from '../../components/Arrows';
import { Card } from '../../components/Card';
import { Footer } from '../../components/Footer';
import Loading from '../../components/Loading';
import { Navbar } from '../../components/Navbar';
import api from '../../services/api';
import '../Franquia/stylesFranquia.css';
import { CardFranquia } from './components/cardFranquia';
import { Title } from './components/title';

const lojasFranquias = [
  {
    title: 'Loja Compacta',
    description:
      'Esse modelo permite ser instalado em pontos de 30 a 100m², que tenham um bom fluxo de pessoas, visibilidade e facilidade de acesso.',
    informations: [
      {
        title: 'Investimento Médio',
        value: 'R$150 mil',
      },
      {
        title: 'Taxa de franquia',
        value: 'R$40.000,00',
      },
      {
        title: 'Área',
        value: '30m² a 100m²',
      },
      {
        title: 'Faturamento médio mensal',
        value: 'R$60 mil',
      },
      {
        title: 'Lucro médio mensal',
        value: '18% a 22%',
      },
      {
        title: 'Payback em meses',
        value: '12 a 36',
      },
      {
        title: 'Royalties 3%',
        value: 'Faturamento brutos',
      },
      {
        title: 'Taxa de publicidade',
        value: '2%',
        details: 'do faturamento bruto',
      },
    ],
  },
  {
    title: 'Loja Premium',
    description:
      'Esse modelo oferece mais opções de produtos, com máquinas de sorvetes expresso, e pode ser instalado em pontos de 100 a 200m², que tenham um bom fluxo de pessoas, visibilidade e facilidade de acesso.',
    informations: [
      {
        title: 'Investimento Médio',
        value: 'R$220 mil',
      },
      {
        title: 'Taxa de franquia',
        value: 'R$40.000,00',
      },
      {
        title: 'Área',
        value: '100m² a 200m²',
      },
      {
        title: 'Faturamento médio mensal',
        value: 'R$80 mil',
      },
      {
        title: 'Lucro médio mensal',
        value: '18% a 22%',
      },
      {
        title: 'Payback em meses',
        value: '12 a 36',
      },
      {
        title: 'Royalties 3%',
        value: 'Faturamento brutos',
      },
      {
        title: 'Taxa de publicidade',
        value: '2%',
        details: 'do faturamento bruto',
      },
    ],
  },
  {
    title: 'Loja Conceito',
    description:
      'Possuindo um espaço físico maior, esse modelo consegue atender uma grande quantidade de clientes, tem fabricação do sorvete artesanal fresco na própria loja, gerando uma maior margem de lucro, e oferece diversos espaços instagramáveis. Pode ser instalado em pontos de 150 a 400m², que tenham um bom fluxo de pessoas, visibilidade, e facilidade de acesso em cidades com mais de 80 mil habitantes.',
    informations: [
      {
        title: 'Investimento Médio',
        value: 'R$420 mil',
      },
      {
        title: 'Taxa de franquia',
        value: 'R$60 mil',
      },
      {
        title: 'Área',
        value: '150m² a 400m²',
      },
      {
        title: 'Faturamento médio mensal',
        value: 'R$120 mil',
      },
      {
        title: 'Lucro médio mensal',
        value: '20% a 28%',
      },
      {
        title: 'Payback em meses',
        value: '12 a 36',
      },
      {
        title: 'Royalties 3%',
        value: 'Faturamento brutos',
      },
      {
        title: 'Taxa de publicidade',
        value: '2%',
        details: 'do faturamento bruto',
      },
    ],
  },
];

export function Franquia() {
  const [index, setIndex] = useState(0);
  const [dataState, setData] = useState({});
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [selected, setSelected] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const array = [
    {
      title: 'APRESENTAÇÃO E CADASTRO',
      text: 'Apresentação da marca e do modelo de negócio em reunião presencial ou online e envio da ficha de cadastro',
    },
    {
      title: 'ANÁLISE DO CANDIDATO',
      text: 'Envio da ficha de cadastro e análise do perfil do candidato.Posteriormente, a aprovação.',
    },
    {
      title: 'COF E PRÉ CONTRATO',
      text: 'Preenchimento da circular de oferta de franquia e assinaturado pré contrato',
    },
    {
      title: 'ANÁLISE DE PONTO',
      text: 'Indicação e análise da cidade e ponto comercial',
    },
    {
      title: 'PESQUISAS E VIABILIDADE',
      text: 'Pesquisas e estudo de viabilidade',
    },
    {
      title: 'APROVAÇÃO E ASSINATURA',
      text: 'Aprovação e assinatura do contrato do ponto comercial. ',
    },
    {
      title: 'CONTRATO DE FRANQUIA',
      text: 'Assinatura do contrato de franquia e implantação.',
    },
  ];
  const getId = (index) => `${index + 1}`;
  const getItems = () =>
    Array(7)
      .fill(0)
      .map((_, ind) => ({
        id: getId(ind),
        title: array[ind].title,
        text: array[ind].text,
      }));
  const handleItemClick = (itemId) => () => setSelected(itemId);
  const [items] = useState(getItems);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const onOpenModal = () => setModalIsOpen(true);
  const onCloseModal = () => setModalIsOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === 'state' && value) {
      getCityList(value);

      return;
    }
  };
  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }
  useEffect(() => {
    getStates();
  }, []);

  function getCityList(estado) {
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`,
      )
      .then((city) => {
        setCity(city.data);
      })
      .catch((error) => {
        console.log('getCityList error:', error);
      });
  }

  function closeModal() {
    setModalIsOpen(false);
  }
  async function sendForm() {
    // e.preventDefault()
    try {
      setIsLoading(true);
      let parameters = {
        searchFunctionality: 'sendFormFranquias',
        userType: 'site',
        data: dataState,
      };
      let res = await api.post('/site', parameters);

      if (!res.data.success) return;

      setIsLoading(false);

      onOpenModal();
      ModalConfirm();
      setData({});
    } catch (e) {
      console.log(e);
    }
  }

  function ModalConfirm() {
    return (
      <Modal
        open={modalIsOpen}
        onClose={onCloseModal}
        classNames={{ modal: 'custom-modal-confirm modal-franquia' }}
      >
        <div className="container-fluid modal-confirm-form">
          <div className="row ">
            <div className="text-center mb-5">
              <img src={folhaModal} alt="" className="image-modal-confirm" />
            </div>
            <div className="color-purple font-15 font-book text-center">
              <span className="oq-card-title">Cadastro enviado</span>
              <p>
                Em breve entraremos em contato com você. Para mais informações,
                basta entrar em contato através do nosso whatsapp abaixo.
              </p>
            </div>
            <div className="col-12 m-auto text-center py-3">
              <div
                type="button "
                className="confirm-button-modal m-auto  animation-hover  "
                onClick={() => onCloseModal()}
              >
                <span>Beleza!</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  function getStates(e) {
    axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');

        setState(sortedStates);
        setLoadingState(false);
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  useEffect(() => {
    (async () => {
      try {
        AOS.init();
        // const dataScrollContainer = document.querySelector(
        //   '[data-scroll-container]',
        // );
        // if (!dataScrollContainer) {
        //   console.warn(
        //     'locomotive-scroll: [data-scroll-container] dataset was not found. You likely forgot to add it which will prevent Locomotive Scroll to work.',
        //   );
        // }
        // const scroller = new LocomotiveScroll({
        //   el: document.querySelector('[data-scroll-container]'),
        //   smooth: true,
        //   multiplier: 1.8,
        // });
        // // const scroll = new LocomotiveScroll();
        // // const scroll = new locomotiveScroll({
        // //   el: scrollRef.current,
        // //   // el: document.querySelector('[data-scroll-container]'),
        // //   smooth: true,
        // //   multiplier: 1.8,
        // // });
        // new ResizeObserver(() => scroller.update()).observe(
        //   document.querySelector('[data-scroll-container]'),
        // );
        // return () => {
        //   window.locomotive?.destroy();
        // };
      } catch (error) {
        console.log('errooo', error);
      }
    })();
  }, []);

  return (
    <>
      {loadingState ? (
        <Loading />
      ) : (
        <>
          <Navbar />
          <div data-scroll-container>
            {/* <div className="scroll" ref={scrollRef}> */}
            <section>
              <div class="carousel-first">
                <div
                  className="row"
                  style={{
                    // paddingBottom: '2vh',
                    marginLeft: '-25px',
                    marginRight: '-25px',
                  }}
                >
                  <img src={aFranquia} alt="" width="100%" />
                </div>
              </div>
            </section>
            <section
              className="background-roxo p-md-5 p-lg-5 p-2 height-100vh franquias d-flex"
              data-scroll-section
            >
              <div className="container col-12 d-md-flex p-md-5  py-5 container-franquia">
                <div className="col-12 col-md-5 px-md-5 ">
                  <h1
                    className="color-pink-franquia "
                    data-aos="fade-down"
                    data-aos-duration="2000"
                  >
                    Nosso paraíso tropical cria raízes por onde chega!
                  </h1>
                </div>
                <div className="col-12 col-md-6 ">
                  <p
                    className="color-white text-nosso-paraiso"
                    data-aos="fade-up-left"
                    data-aos-duration="2000"
                  >
                    Somos uma marca que entrega mais do que serviços e produtos,
                    acreditamos nas pessoas e no seu poder de inspirar e
                    transformar, e juntos com elas podemos fazer mais do que
                    vender e gerar resultados financeiros, queremos contribuir
                    para um mundo melhor de se viver. <br />
                    <br /> Oferecer produtos de qualidade, ambiente confortável,
                    e excelência em atendimento são obrigações de qualquer
                    empresa. Diferencial para nós é nos conectarmos com as
                    pessoas, é promovermos a empatia, é estarmos engajados
                    ativamente na criação do futuro que desejamos: um mundo mais
                    gentil e inclusivo. <br /> <br />O mercado de gelados é um
                    dos segmentos que mais cresce no mundo, e reserva ótimas
                    prospecções para as próximas décadas, e é onde encontramos
                    uma forma divertida de fazer o que acreditamos e oferecer
                    uma oportunidade de investimento inteligente.
                  </p>
                  <div className="col-12">
                    <a href="#franquia">
                      <div
                        type="button "
                        className="franqueado button-franqueado mt-5 animation-hover  "
                      >
                        <span className="letter-spacing">
                          QUERO SER UM FRANQUEADO
                        </span>
                      </div>
                    </a>
                  </div>
                  {/* <a href="#franquia" data-scroll-to>
                <div
                  type="button"
                  className="franqueado button-franqueado mt-5 m-auto animation-hover letter-spacing"
                >
                  <span>QUERO SER UM FRANQUEADO</span>
                </div>
              </a> */}
                </div>
              </div>
            </section>
            <br />
            <section className="container col-12 col-md-12 section-pq-escolher-tropzz">
              <div className="col-12 col-md-12  m-auto p-md-5 pb-5 my-5 ">
                <Title
                  data={`Por que escolher
                  <br /> a <span>franquia</span> Tropzz`}
                />
                {/* <h1
                  className="title-h1 color-pink pb-5 p-md-0 p-1 title-pq-franquia"
                  data-aos="fade-up-left"
                  data-aos-duration="2000"
                >
                  Por que escolher
                  <br /> a <span>franquia</span> Tropzz
                </h1> */}
                <br />
                <br />
                <div
                  className="col-12  d-sm-flex  color-purple font-book p-0 py-sm-2  text-md-left"
                  data-aos="fade-up-left"
                  data-aos-duration="2000"
                >
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia ">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">1</h1>
                    <p>Segmento em grande expansão</p>
                  </div>
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia ">
                    <img
                      className="m-right-3 d-none d-sm-inline"
                      src={check}
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">2</h1>

                    <p>Manuais de operação e procedimentos</p>
                  </div>
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">3</h1>

                    <p>Fornecedores homologados</p>
                  </div>
                </div>
                <div
                  className="col-12  d-sm-flex  color-purple font-book p-0 py-sm-2  text-md-left"
                  data-aos="fade-up-left"
                  data-aos-duration="2000"
                >
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">4</h1>

                    <p>Lançamentos de novos produtos</p>
                  </div>
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">5</h1>

                    <p>Mais de 1.000 combinações de produtos</p>
                  </div>
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia ">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">6</h1>

                    <p className="">Treinamentos gerenciais e operacionais</p>
                  </div>
                </div>
                <div
                  className="col-12  d-sm-flex  color-purple font-book p-0 py-sm-2  text-md-left"
                  data-aos="fade-up-left"
                  data-aos-duration="2000"
                >
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">7</h1>

                    <p>Marca consolidada no mercado</p>
                  </div>
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">8</h1>

                    <p>Sistema de gestão de implantação e Suporte de campo </p>
                  </div>
                  <div className="col-md-4 col-12  d-sm-flex p-3-right  text-sm-left text-align-center-franquia">
                    <img
                      src={check}
                      className="m-right-3 d-none d-sm-inline"
                      alt=""
                      width={30}
                      height={30}
                    />
                    <h1 className="d-grid d-sm-none icon-number-franquia">9</h1>

                    <p>Suporte de consultores na inauguração </p>
                  </div>
                </div>
              </div>
            </section>
            <div class="ocean" data-scroll-section>
              <div class="wave"></div>
            </div>
            <section data-scroll-section>
              <div className="background-purple pt-5">
                <div className="container d-flex justify-content-center p-5">
                  {lojasFranquias.map((loja, idx) => {
                    return (
                      <>
                        <div
                          type="button "
                          className={`${
                            index === idx ? 'button-a-tropzz' : ''
                          } button-carrosel`}
                          onClick={(e) => handleSelect(idx)}
                        >
                          <span className="text-uppercase">{loja.title}</span>
                        </div>
                      </>
                    );
                  })}
                </div>

                <CardFranquia
                  index={index}
                  loja={lojasFranquias}
                  handleSelect={handleSelect}
                />
              </div>
            </section>
            <section className="background-light-green" data-scroll-section>
              <div className="container col-12 d-md-flex  background-light-green m-auto perfil-ideal">
                <div className="container col-12 col-md-4 d-md-flex py-5 px-sm-5">
                  <h1
                    className="title-perfil color-purple-franquia m-md-auto"
                    data-aos="fade-up-left"
                    data-aos-duration="2000"
                  >
                    <span>O</span> perfil <br /> ideal <span>do</span> <br />
                    franqueado
                  </h1>
                </div>
                <div className="col-12 col-md-8 py-5 m-auto">
                  <div className="d-md-flex">
                    <div
                      className="col-md-4 col-12 p-3 justify-content-center text-center text-perfil-franqueado px-md-0 px-5 font-medium"
                      data-aos="fade-up-left"
                      data-aos-duration="2000"
                    >
                      <FirstFranqueado />
                      <h6 className="py-3 py-md-1 subtitle-perfil">
                        Trabalho em equipe
                      </h6>
                      <p>
                        Tem habilidade para lidar com pessoas e aceita críticas
                        construtivas
                      </p>
                    </div>
                    <div
                      className="col-md-4 p-3 justify-content-center text-center text-perfil-franqueado px-md-0 px-5 font-medium"
                      data-aos="fade-up-left"
                      data-aos-duration="2000"
                    >
                      <SecondtFranqueado />
                      <h6 className="py-3 py-md-1 subtitle-perfil">
                        Liderança
                      </h6>
                      <p>
                        Toma decisões com sabedoria e é exemplo do que quer ver
                        nas pessoas
                      </p>
                    </div>
                    <div
                      className="col-md-4 p-3 justify-content-center text-center text-perfil-franqueado px-md-0 px-5 font-medium"
                      data-aos="fade-up-left"
                      data-aos-duration="2000"
                    >
                      <ThirdFranqueado />
                      <h6 className="py-3 py-md-1 subtitle-perfil">
                        Espírito empreendedor
                      </h6>
                      <p>
                        Se identifica com a posição de empresário e quer estar à
                        frente do negócio
                      </p>
                    </div>
                  </div>
                  <div className="d-md-flex">
                    <div
                      className="col-md-4 p-3 justify-content-center text-center text-perfil-franqueado px-md-0 px-5 font-medium"
                      data-aos="fade-up-left"
                      data-aos-duration="2000"
                    >
                      <FourFranqueado />
                      <h6 className="py-3 py-md-1 subtitle-perfil">
                        Princípios equivalentes
                      </h6>
                      <p>
                        Se identifica com nossos valores, e preza por empatia e
                        transparência
                      </p>
                    </div>
                    <div
                      className="col-md-4 p-3 justify-content-center text-center text-perfil-franqueado px-md-0 px-5 font-medium"
                      data-aos="fade-up-left"
                      data-aos-duration="2000"
                    >
                      <FiveFranqueado />
                      <h6 className="py-3 py-md-1 subtitle-perfil">
                        Investimento
                      </h6>
                      <p>Tem disponibilidade financeira</p>
                    </div>
                    <div
                      className="col-md-4 p-3 justify-content-center text-center text-perfil-franqueado px-md-0 px-5 font-medium"
                      data-aos="fade-up-left"
                      data-aos-duration="2000"
                    >
                      <SixFranqueado />
                      <h6 className="py-3 py-md-1 subtitle-perfil">
                        Inconformação
                      </h6>
                      <p>
                        Busca por melhorias contínuas com dedicação e
                        determinação
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="background-purple passos-ate-ser-tropzz">
              <div className="container ">
                <h1 className="title-h1 color-pink p-4 passos-title">
                  <span>Os</span> passos <span>até ser</span> Tropzz
                </h1>
              </div>
              <div className="px-4">
                <div>
                  <div className="border-passos"></div>
                  <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    onWheel={onWheel}
                    className="arrows-passos"
                  >
                    {items.map(({ id, title, text }) => (
                      <Card
                        title={id}
                        itemId={id} // NOTE: itemId is required for track items
                        key={id}
                        onClick={handleItemClick(id)}
                        selected={id === selected}
                        texts={text}
                        titleCard={title}
                      />
                    ))}
                    <img src={tropzzLogo} alt="" />
                  </ScrollMenu>
                </div>
              </div>
            </section>
            <section
              className="background-white"
              id="franquia"
              data-scroll-section
            >
              <div className="container col-12 d-md-flex p-5">
                <div className="col-12 col-md-6 m-3-right">
                  <h1
                    className="color-purple title-ficou-interessado"
                    data-aos="fade-up-left"
                    data-aos-duration="2000"
                  >
                    <strong>
                      Ficou <br />
                      interessado?
                    </strong>
                  </h1>
                  <p
                    className="color-purple text-ficou-interessado"
                    data-aos="fade-up-left"
                    data-aos-duration="2000"
                  >
                    Pega sua mochilinha e corre pro abraço!
                  </p>
                  <img
                    src={mochila}
                    alt=""
                    width={'100%'}
                    className="mochila"
                    data-aos="fade-up-left"
                    data-aos-duration="2000"
                  />
                </div>
                <div
                  className="col-12 col-md-12 pt-5"
                  data-aos="fade-up-left"
                  data-aos-duration="2000"
                >
                  <div className="col-12 col-md-6 pt-5">
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.name ? 'form-error-input' : ''
                          }`}
                          type="text"
                          placeholder=" "
                          // onChange={(e) => handleInputChange(e)}
                          // required={true}
                          // name="name"
                          value={dataState && dataState.name}
                          {...register('name', {
                            required: true,
                            onChange: (e) => handleInputChange(e),
                          })}
                        />
                        {errors.name && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span
                          className={`input__label ${
                            errors.name ? 'form-error-input__label' : ''
                          }`}
                        >
                          Nome{' '}
                        </span>
                        {errors.name && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.email ? 'form-error-input' : ''
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          // required={true}
                          // name="email"
                          value={dataState && dataState.email}
                          {...register('email', {
                            required: true,
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: 'invalid email address',
                            },
                          })}
                        />
                        {errors.email && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span className="input__label">Email</span>
                        {errors.email && (
                          <span className="form-error">
                            Por favor, verifique se seu e-mail está correto.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <input
                          className={`input__field ${
                            errors.telefone ? 'form-error-input' : ''
                          }`}
                          type="text"
                          placeholder=" "
                          // onChange={(e) => handleInputChange(e)}
                          // required={true}
                          // name="telefone"
                          value={dataState && dataState.telefone}
                          {...register('telefone', {
                            required: true,
                            onChange: (e) => handleInputChange(e),
                          })}
                        />
                        {errors.telefone && (
                          <i className="fa fa-exclamation icon icon-validate-input"></i>
                        )}
                        <span
                          className={`input__label ${
                            errors.telefone ? 'form-error-input__label' : ''
                          }`}
                        >
                          Telefone
                        </span>
                        {errors.telefone && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>

                    <div className="mt-3">
                      <label className="input">
                        <select
                          className={`input__field ${
                            errors.comoConheceu ? 'form-error-input' : ''
                          }`}
                          type="text"
                          placeholder=" "
                          onChange={(e) => handleInputChange(e)}
                          // required={true}
                          // name="comoConheceu"
                          value={dataState && dataState.comoConheceu}
                          {...register('comoConheceu', {
                            required: true,
                            onChange: (e) => handleInputChange(e),
                          })}
                        >
                          <option value="">Selecione uma opção</option>

                          <option value="redes-sociais">Redes Sociais</option>
                          <option value="google">Google</option>
                          <option value="amigos">Amigos</option>
                          <option value="outros">Outros</option>
                        </select>
                        <span
                          className={`input__label ${
                            errors.comoConheceu ? 'form-error-input__label' : ''
                          }`}
                        >
                          Como conheceu a Tropzz?
                        </span>
                        {errors.comoConheceu && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="mt-3">
                      <label className="input">
                        <select
                          className={`input__field ${
                            errors.investimento ? 'form-error-input' : ''
                          }`}
                          type="text"
                          placeholder=" "
                          // onChange={(e) => handleInputChange(e)}
                          // required={true}
                          name="investimento"
                          value={dataState && dataState.investimento}
                          {...register('investimento', {
                            required: true,
                            onChange: (e) => handleInputChange(e),
                          })}
                        >
                          <option value="">Selecione uma opção</option>
                          <option value="15000">
                            Loja Compacta - Á partir de R$150.000,00
                          </option>
                          <option value="20000">
                            Loja Premium - Á partir de R$220.000,00
                          </option>
                          <option value="25000">
                            Loja Conceito - Á partir de R$420.000,00
                          </option>
                          <option value="35000">Outros</option>
                        </select>
                        <span
                          className={`input__label ${
                            errors.investimento ? 'form-error-input__label' : ''
                          }`}
                        >
                          Modelo desejado
                        </span>
                        {errors.investimento && (
                          <span className="form-error">
                            Esse campo é obrigatório.
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="col-12 col-md-12 d-flex">
                      <div className="col-4 p-right-4 gap-3 p-sm-right-3 ">
                        <div className="mt-3">
                          <label className="input">
                            <select
                              className={`input__field ${
                                errors.state ? 'form-error-input' : ''
                              }`}
                              type="text"
                              placeholder=" "
                              onChange={(e) => handleInputChange(e)}
                              required={true}
                              name="state"
                              value={dataState && dataState.state}
                              {...register('state', {
                                required: true,
                                onChange: (e) => handleInputChange(e),
                              })}
                            >
                              <option value="">Estado de interesse</option>
                              {state &&
                                state.map((state) => {
                                  return (
                                    <option value={state.sigla}>
                                      {state.sigla}
                                    </option>
                                  );
                                })}
                            </select>
                            <span className="input__label"></span>
                            {errors.state && (
                              <span className="form-error">
                                Esse campo é obrigatório.
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="mt-3">
                          <label className="input">
                            <select
                              className={`input__field ${
                                errors.city ? 'form-error-input' : ''
                              }`}
                              type="text"
                              placeholder=" "
                              // onChange={(e) => handleInputChange(e)}
                              required={true}
                              name="city"
                              value={dataState && dataState.city}
                              {...register('city', {
                                required: true,
                                onChange: (e) => handleInputChange(e),
                              })}
                            >
                              <option value="">Cidade de interesse</option>
                              {city &&
                                city.map((city) => {
                                  return (
                                    <option value={city.nome}>
                                      {city.nome}
                                    </option>
                                  );
                                })}
                            </select>

                            <span className="input__label"></span>
                            {errors.city && (
                              <span className="form-error">
                                Esse campo é obrigatório.
                              </span>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div
                        type="button "
                        className="about-us mt-5 animation-hover  "
                        onClick={handleSubmit(sendForm)}
                      >
                        <span className="letter-spacing">ENVIAR</span>
                      </div>
                    </div>
                    {/* <div className=" d-flex justify-content-end pt-3">
                  <div
                    type="button"
                    className="about-us"
                    onClick={handleSubmit(sendForm)}
                  >
                    <span>ENVIAR</span>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </section>
            <div class="ocean" data-scroll-section>
              <div
                class="wave"
                data-scroll
                data-scroll-speed="2"
                data-scroll-position="top"
                data-scroll-direction="horizontal"
              ></div>
            </div>
            {/* <section className='background-purple'>
          <div>
          <div
              type="button"
              className={`${
                index === 1 ? 'button-a-tropzz' : ''
              } button-carrosel`}
              onClick={(e) => handleSelect(1)}
            >
              <span>PORQUE EXISTIMOS</span>
            </div>
          </div>
        </section> */}
            <Footer data-scroll-section />
          </div>
          {/* </div> */}
          {ModalConfirm()}
        </>
      )}
    </>
  );
}
