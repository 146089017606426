import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import './stylesCard.css';

export function Card({ itemId, selected, onClick, title, titleCard, texts }) {
  console.log(itemId, selected, onClick, title, titleCard, texts);
  const visibility = React.useContext(VisibilityContext);

  const visible = visibility.isItemVisible(itemId);

  return (
    <div
      onClick={() => onClick()}
      role="button"
      tabIndex={0}
      className="card-franquia pontilhada font-book"
    >
      <div className="div-card-efeito">
        <div
          style={{
            backgroundColor: !selected ? '#009CDE' : '#DA0080',
          }}
          id="circle"
          className="circle-passos-franquia"
        >
          {title}
        </div>
        <div
          style={{ color: 'white', fontSize: '130%', paddingRight: '10rem' }}
          className="font-book font-weight-bold title-card"
        >
          {titleCard}
        </div>
        <div
          style={{ color: 'white', paddingRight: '5rem' }}
          className="font-book text-card"
        >
          <span>{selected ? texts : ''}</span>
        </div>
      </div>
    </div>
  );
}
