import AOS from 'aos';
import React, { useEffect, useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { Carousel } from 'react-bootstrap';
import CountUp from 'react-countup';
import { isMobile } from 'react-device-detect';
import VisibilitySensor from 'react-visibility-sensor';
import _ from 'underscore';
import cidades from '../../assets/images/cidades.png';
import equipe from '../../assets/images/equipe.png';
import suco from '../../assets/images/suco.png';
import tropzzCarinha from '../../assets/images/tropzzCarinha.png';
import { ReactComponent as ArrowLeft } from '../../assets/svg/buttonLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/buttonRight.svg';
import { Footer } from '../../components/Footer';
import { Franquias } from '../../components/Franquias';
import Loading from '../../components/Loading';
import { Navbar } from '../../components/Navbar';
import api from '../../services/api';
import './stylesTropzz.css';
const AutoplaySlider = withAutoplay(AwesomeSlider);
export function Tropzz() {
  const [index, setIndex] = useState(0);
  const [texts, setTexts] = useState();
  const [adicionais, setAdicionais] = useState();
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [banner, setBanner] = useState();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const onComplete = () => {
    console.log('Completed! 👏');
  };

  const onStart = () => {
    console.log('Started! 💨');
  };

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  useEffect(() => {
    getTexts();
  }, []);
  useEffect(() => {
    getAdicionais();
  }, []);
  async function getAdicionais() {
    let parameters = {
      searchFunctionality: 'getAdicionaisSessionTropzz',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    setAdicionais(res.data.data);
    setLoadingState(false);
    // console.log(res.data);
  }

  async function getTexts() {
    let parameters = {
      searchFunctionality: 'getTexts',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    setTexts(res.data.data);
    console.log('🚀 ~ file: index.jsx:73 ~ getTexts ~ res:', res);
  }
  useEffect(() => {
    getBanners();
  }, []);

  async function getBanners() {
    let parameters = {
      searchFunctionality: 'loadBannerTropzz',
      userType: 'site',
    };
    const res = await api.post(`/site`, parameters);
    setBanner(res.data.data);
    console.log('🚀 ~ file: index.jsx:87 ~ getBanners ~ res:', res);
  }
  useEffect(() => {
    (async () => {
      try {
        AOS.init();
        // const dataScrollContainer = document.querySelector(
        //   '[data-scroll-container]',
        // );
        // if (!dataScrollContainer) {
        //   console.warn(
        //     'locomotive-scroll: [data-scroll-container] dataset was not found. You likely forgot to add it which will prevent Locomotive Scroll to work.',
        //   );
        // }
        // const scroller = new LocomotiveScroll({
        //   el: document.querySelector('[data-scroll-container]'),
        //   smooth: true,
        //   multiplier: 1.8,
        // });
        // // const scroll = new LocomotiveScroll();
        // // const scroll = new locomotiveScroll({
        // //   el: scrollRef.current,
        // //   // el: document.querySelector('[data-scroll-container]'),
        // //   smooth: true,
        // //   multiplier: 1.8,
        // // });
        // new ResizeObserver(() => scroller.update()).observe(
        //   document.querySelector('[data-scroll-container]'),
        // );
        // return () => {
        //   window.locomotive?.destroy();
        // };
      } catch (error) {
        console.log('errooo', error);
      }
    })();
  }, []);

  return (
    <>
      {loadingState ? (
        <Loading />
      ) : (
        <>
          <Navbar />
          <div data-scroll-container>
            <section data-scroll-section>
              <div class="carousel-first">
                <div
                  className="row"
                  style={{
                    // paddingBottom: '2vh',
                    marginLeft: '-25px',
                    marginRight: '-25px',
                  }}
                >
                  {isMobile && (
                    <AutoplaySlider
                      play={true}
                      interval={5000}
                      cancelOnInteraction={true}
                      mobileTouch={true}
                      infinite={true}
                      organicArrows={false}
                      bullets={true}
                      touchEnabled={true}
                      touch={true}
                      // buttonContentRight={
                      //   <div className="button-content-first-slide">
                      //     <ArrowRight />
                      //   </div>
                      // }
                      // buttonContentLeft={
                      //   <div className="button-content-first-slide">
                      //     <ArrowLeft />
                      //   </div>
                      // }
                      // fillParent={true}
                      animation="cubeAnimation"
                    >
                      {_.map(
                        banner && banner.bannerImageMobile,
                        (banner, i) => {
                          return (
                            <div
                              key={i}
                              className="bannercarousel bannercarouselMobile"
                              style={{
                                padding: '0px',
                                width: '100%',
                                backgroundImage: `url(${banner.url})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                              }}
                            ></div>
                          );
                        },
                      )}
                    </AutoplaySlider>
                  )}
                  {!isMobile && (
                    <AutoplaySlider
                      play={true}
                      interval={5000}
                      cancelOnInteraction={true}
                      mobileTouch={true}
                      infinite={true}
                      organicArrows={false}
                      bullets={false}
                      touchEnabled={true}
                      touch={true}
                      buttonContentRight={
                        <div className="button-content-first-slide">
                          <ArrowRight />
                        </div>
                      }
                      buttonContentLeft={
                        <div className="button-content-first-slide">
                          <ArrowLeft />
                        </div>
                      }
                      // fillParent={true}
                      animation="cubeAnimation"
                    >
                      {banner &&
                        _.map(banner.bannerImage, (banner, i) => {
                          return (
                            <div
                              key={i}
                              className="bannercarousel "
                              style={{
                                padding: '0px',
                                width: '100%',
                                backgroundImage: `url(${banner.url})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                              }}
                            ></div>
                          );
                        })}
                    </AutoplaySlider>
                  )}
                </div>
              </div>
              {/* <div>
                <img src={headerTropzz} alt="" width="100%" height="50%" />
              </div> */}
            </section>
            <section data-scroll-section>
              <div className="background-blue pt-5">
                <div style={{ marginTop: '3rem' }}>
                  <div className="">
                    <Carousel
                      activeIndex={index}
                      onSelect={(e) => handleSelect(e)}
                      fade
                      indicators={false}
                      nextIcon={
                        <div className="button-content-section-a-tropzz">
                          <ArrowRight />
                        </div>
                      }
                      prevIcon={
                        <div className="button-content-section-a-tropzz">
                          <ArrowLeft />
                        </div>
                      }
                    >
                      {adicionais &&
                        adicionais.questions &&
                        adicionais.questions.map((adicional, index) => {
                          // console.log(adicional);
                          return (
                            <Carousel.Item>
                              <div className="container d-md-flex px-5 mx-auto">
                                <div className="col-12 col-md-7 m-auto text-slide">
                                  <h1>{adicional.titleAdicional}</h1>
                                  <div>
                                    {/* <span> {adicional.subtitleAdicional}</span> */}
                                  </div>
                                  <br />
                                  <div>
                                    <span>{adicional.textAdcional}</span>
                                  </div>
                                </div>
                                <div className="col-12 col-md-5 m-auto image-slider d-none d-md-grid d-lg-grid ">
                                  <img
                                    className="d-block w-100"
                                    src={adicional.urladicional}
                                    alt="Second slide"
                                  />
                                </div>
                              </div>
                            </Carousel.Item>
                          );
                        })}
                    </Carousel>
                  </div>
                  <div className="container d-md-flex d-none  px-5 margin-bottom-5">
                    {adicionais &&
                      adicionais.questions &&
                      adicionais.questions.map((adicional, i) => {
                        return (
                          <>
                            <div
                              type="button "
                              className={`${
                                i === index ? 'button-a-tropzz' : ''
                              } button-carrosel`}
                              onClick={(e) => handleSelect(i, e)}
                            >
                              <span>{adicional.titleAdicional}</span>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div class="ocean">
                  <div
                    class="wave-white"
                    data-scroll
                    data-scroll-speed="3"
                    data-scroll-position="top"
                    data-scroll-direction="horizontal"
                  ></div>
                </div>
              </div>
              {/* <img
            class="backgound-azul"
            src={ondaBranca}
            alt=""
            // data-scroll
            // data-scroll-speed="2"
            // data-scroll-position="top"
            // data-scroll-direction="horizontal"
          /> */}
            </section>
            <section className="section-counter" data-scroll-section>
              <div className="container m-auto pb-5 pt-5 ">
                <div className="col-12 d-md-flex m-auto">
                  <div className="col-12 col-md-4 justify-content-center text-center">
                    <img src={cidades} alt="" className="mb-4" />
                    <h1>
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        delayedCall
                        offset={{
                          top: 10,
                        }}
                      >
                        <CountUp
                          className="counter"
                          start={0}
                          end={8}
                          redraw={true}
                          duration={4.75}
                          useEasing={true}
                          useGrouping={true}
                          separator=" "
                          decimals={0}
                          decimal=","
                          prefix=""
                          suffix=""
                          onComplete={onComplete}
                          onStart={onStart}
                        ></CountUp>
                      </VisibilitySensor>
                    </h1>
                    {/* <h1 className="counter">08</h1> */}
                    <p className="counter-subtitle">cidades</p>
                  </div>
                  <div className="col-12 col-md-4 justify-content-center text-center">
                    <img src={suco} alt="" className="mb-4" />
                    <h1>
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        delayedCall
                        offset={{
                          top: 10,
                        }}
                      >
                        <CountUp
                          className="counter"
                          start={0}
                          end={500}
                          redraw={true}
                          duration={4.75}
                          useEasing={true}
                          useGrouping={true}
                          separator=" "
                          decimals={0}
                          decimal=","
                          prefix=""
                          suffix=""
                          onComplete={onComplete}
                          onStart={onStart}
                        ></CountUp>
                      </VisibilitySensor>
                    </h1>
                    {/* <h1 className="counter">5k</h1> */}
                    <p className="counter-subtitle">
                      litros de açaí <br />
                      vendidos por mês
                    </p>
                  </div>
                  <div className="col-12 col-md-4 justify-content-center text-center">
                    <img src={tropzzCarinha} alt="" className="mb-4" />
                    <h1>
                      <VisibilitySensor
                        onChange={onVisibilityChange}
                        delayedCall
                        offset={{
                          top: 10,
                        }}
                      >
                        <CountUp
                          className="counter"
                          start={0}
                          end={400000}
                          redraw={true}
                          duration={4.75}
                          useEasing={true}
                          useGrouping={true}
                          separator="."
                          decimals={0}
                          decimal=","
                          prefix=""
                          suffix=""
                          onComplete={onComplete}
                          onStart={onStart}
                        ></CountUp>
                      </VisibilitySensor>
                    </h1>
                    {/* <h1 className="counter">400k</h1> */}
                    <p className="counter-subtitle">clientes satisfeitos</p>
                  </div>
                </div>
              </div>
            </section>
            <section data-scroll-section>
              <div className="background-purple d-flex">
                <div
                  className="col-md-3 col-lg-3 col-12 align-self-center nossa-equipe   animate__backInRight "
                  data-scroll
                  data-scroll-speed="1"
                  // data-scroll-position="right"
                  data-scroll-direction="horizontal"
                >
                  <div className="col-12 p-0 m-0 d-md-none d-lg-none d-flex">
                    <img src={equipe} alt="" width="100%" />
                  </div>
                  <h1
                    className="title-h1-nossa-equipe"
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-sine"
                  >
                    NOSSA <br />
                    EQUIPE <br />{' '}
                    <span className="letter-spacing">TROPZZERA</span>
                  </h1>
                </div>
                <div className="col-9 p-0 m-0 text-end d-md-flex d-lg-flex d-none">
                  <img src={equipe} alt="" width="100%" />
                </div>
              </div>
              <div class="ocean">
                <div
                  class="wave-bege"
                  data-scroll
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                ></div>
              </div>
              {/* <img
            src={ondaRoxa}
            className="background-palha"
            alt=""
            width="100%"
          /> */}
            </section>
            <Franquias params={texts} data-scroll-section />
            <div class="ocean" data-scroll-section>
              {/* <div class="wave background-palha"></div> */}
              <div
                class="wave background-palha"
                data-scroll
                data-scroll-speed="2"
                data-scroll-position="top"
                data-scroll-direction="horizontal"
              ></div>
            </div>

            <Footer data-scroll-section />
          </div>
        </>
      )}
    </>
  );
}
