import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
export function Franquias(props) {
  // console.log('propsss', props.params.section2.text);
  AOS.init();

  return (
    <section className="section-3">
      <div className="container d-md-flex d-lg-flex">
        <div className="col-12 col-md-7 in-city p-0">
          <h1
            className="title-h1-franquias "
            data-aos="fade-down"
            data-aos-duration="2000"
          >
            Já imaginou um <br /> <strong>paraíso tropical </strong> <br /> na{' '}
            <strong>sua cidade?</strong>
          </h1>
          <div
            className="col-12 col-md-5 video d-sm-none my-5"
            data-aos="fade-up-left"
            data-aos-duration="2000"
          >
            <img
              src={
                props.params &&
                props.params.section2 &&
                props.params.section2.imageSection
              }
              alt="video"
            />
          </div>
          <div
            className=" text-franquia-session"
            data-aos="fade-down"
            data-aos-duration="2000"
            dangerouslySetInnerHTML={
              props.params &&
              props.params.section2 &&
              props.params.section2.text
                ? { __html: props.params.section2.text }
                : null
            }
          />
          <div className="col-12">
            <Link to="/a-franquia">
              <div type="button " className="about-us mt-5 animation-hover  ">
                <span className="letter-spacing">FRANQUIA</span>
              </div>
            </Link>
          </div>
        </div>
        <div
          className="col-12 col-md-5 video d-none d-sm-grid"
          data-aos="fade-up-left"
          data-aos-duration="2000"
        >
          <img
            src={
              props.params &&
              props.params.section2 &&
              props.params.section2.imageSection
            }
            alt="video"
          />
        </div>
      </div>
    </section>
  );
}
