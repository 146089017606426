import { Routes } from './routes';
import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import './styles/global.css';
import 'animate.css';
import { ScrollToTop } from './components/ScrollToTop';

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes />
    </>
  );
}

export default App;
