import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import frameFolha from "../../assets/images/folha.png";
import logoBranca from "../../assets/images/logo-branca.png";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/svg/instagram.svg";
import { ReactComponent as Whatsapp } from "../../assets/svg/whatsapp.svg";
import * as routes from "../../routes";
import api from "../../services/api";
import "./styles.css";

export function Footer() {
  const [currentPage, setCurrentPage] = useState("");
  const [text, setText] = useState("");
  const [dataState, setData] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  function getIsActive(route) {
    if (String(currentPage) === route) {
      return currentPage;
    }
    // return String(currentPage).includes(route);
  }
  function getCurrentPageURL() {
    let { pathname } = window.location;
    setCurrentPage(pathname);
  }
  useEffect(() => {
    getCurrentPageURL();
  }, []);

  async function sendForm(data) {
    if (!data.email) {
      return;
    }
    console.log("dataaa", data);

    let parameters = {
      searchFunctionality: "sendFormFaleConosco",
      userType: "site",
      data: data,
    };

    let res = await api.post("/site", parameters);
    console.log("res", res);
    if (res.data.success) {
      setText("Pronto! Agora você está inscrito na nossa newsletter!");
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section
      className=" background-purple footer"
      data-scroll-section
      data-scroll-target="#franquia"
    >
      <div
        className={`folha-frame d-none ${
          getIsActive(routes.INICIO) ? "d-md-grid d-lg-grid " : ""
        }`}
      >
        <img src={frameFolha} alt="" width="40%" />
      </div>
      <div className="container   pb-5 ">
        <div
          className={`contact pb-5 ${
            getIsActive(routes.INICIO) ? "" : "margin-top"
          }`}
        >
          <img src={logoBranca} alt="logo" />
        </div>
        <div className="container col-12 white d-md-flex">
          <div className=" col-md-4 col-12 padding-right-3 ">
            <div>
              <p>
                A Tropzz é uma rede de franquias de sobremesas geladas que
                oferece o melhor do sabor tropical do Brasil
              </p>
              <p>
                <strong>Gostou do que viu?</strong>
              </p>
              <p>
                Assine nossa newsletter e fique por dentro das últimas
                novidades!
              </p>
            </div>
            <div className="d-flex newsletter_div ">
              <input
                className="newsletter_input"
                type="text"
                name="email"
                placeholder="ex. fulano@gmail.com"
                {...register("email", {
                  required: true,
                  onChange: (e) => handleInputChange(e),
                })}
              />
              <div
                type="button"
                className="newsletter"
                onClick={handleSubmit(sendForm)}
              >
                <span>Assinar</span>
              </div>
            </div>
            {errors.email && (
              <span className="form-error">Esse campo é obrigatório.</span>
            )}
            <span className="">{text}</span>
          </div>
          <div className="col-md-2 col-12 d-inline mx-3">
            <p>Tropzz</p>
            <div className="mb-1">
              <a href="/">Home</a>
            </div>
            <div className="mb-1">
              <a href="/a-franquia">Franquia</a>
            </div>
            <div className="mb-1">
              <a href="">Contato</a>
            </div>
            <div className="mb-1">
              <a href="">Nosso Blog</a>
            </div>
            <div className="mb-1">
              <a
                href="https://linktr.ee/marcatropzz"
                target="_blank"
                rel="noreferrer"
              >
                Delivery
              </a>
            </div>
          </div>
          <div className="col-md-3 col-12 ">
            <p>Nos acompanhe também através das nossas redes sociais</p>
            <div className="d-flex">
              <div className="img px-md-1 icon-footer">
                <a href="">
                  <Facebook />
                </a>
              </div>
              <div className="img px-md-1 icon-footer">
                <a href="">
                  <Instagram />
                </a>
              </div>
              <div className="img px-md-1 icon-footer">
                <a href="">
                  <Whatsapp />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" container white mb-0 pb-5 ">
        <p className="container">
          2022 - Tropzz | Todos os direitos reservados | Desenvolvido por GRUTA
          DESIGN
        </p>
      </div>
    </section>
  );
}
