export function Title({ textBefore, emphasis, textAfter, data }) {
  return (
    <h1
      className="title-h1 color-pink pb-5 p-md-0 p-1 title-pq-franquia"
      data-aos="fade-up-left"
      data-aos-duration="2000"
      dangerouslySetInnerHTML={{ __html: data }}
    ></h1>
  );
}
