import * as firebase from 'firebase';
import { initializeApp } from "firebase/app";
import 'firebase/performance';

const fbConfig = {
  apiKey: 'AIzaSyB3GUNHLLELwPOAJfNxeHZbwthVptTSHJM',
  authDomain: 'paineltropzz.firebaseapp.com',
  databaseURL: 'https://paineltropzz-default-rtdb.firebaseio.com',
  projectId: 'paineltropzz',
  storageBucket: 'paineltropzz.appspot.com',
  messagingSenderId: '350251916712',
  appId: '1:350251916712:web:21ef4585fefac4412b119d',
};

let config = fbConfig;

if (!firebase.apps.length) {
  initializeApp(config);
}
let messaging = null;

const db = firebase.database();
const auth = firebase.auth();
var perf = firebase.performance();

if (firebase.messaging.isSupported()) {
  console.log('suported is firebase messaging');
  try {
    messaging = firebase.messaging();
  } catch (error) {
    console.log('suported messaging but error: ', error);
    throw error;
  }
}

export { db, auth, messaging, perf, firebase };
