import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import coqueiro3 from '../../assets/images/Group 294.png';
import coqueiro2 from '../../assets/images/Group 295.png';
import coqueiro1 from '../../assets/images/foto 14 edit 2 2.png';
import { ReactComponent as ArrowLeft } from '../../assets/svg/buttonLeft.svg';
import { ReactComponent as ArrowRight } from '../../assets/svg/buttonRight.svg';
import { CarouselFirst } from '../../components/CarouselFirst';
import { Navbar } from '../../components/Navbar';
import './stylesHome.css';

import AOS from 'aos';
import 'aos/dist/aos.css';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';
// import { ReactComponent as Facebook } from '../../assets/svg/facebook.svg';
// import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg';
// import { ReactComponent as Whatsapp } from '../../assets/svg/whatsapp.svg';

import { AddressInput } from '../../components/AddressInput';
import { Form } from '../../components/Form';
import { Franquias } from '../../components/Franquias';

import { Footer } from '../../components/Footer';

import { useEffect } from 'react';
import api from '../../services/api';

import Loading from '../../components/Loading';
import { Title } from '../Franquia/components/title';

export function Home() {
  const [index, setIndex] = useState(0);
  const [texts, setTexts] = useState();
  const [adicionais, setAdicionais] = useState();
  const [selectedImg, setSelectedImg] = useState(null);
  const [instagram, setInstagram] = useState([]);
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const [loadingState, setIsLoading] = useState(true);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const loadInit = async () => {
      getTexts();
      getAdicionais();
    };
    loadInit();
  }, []);

  function getTexts() {
    let parameters = {
      searchFunctionality: 'getTexts',
      userType: 'site',
    };
    api.post(`/site`, parameters).then((res) => {
      setTexts(res.data.data);

      setInstagram(res.data.data.section4.instagram);
    });
  }

  function getAdicionais() {
    let parameters = {
      searchFunctionality: 'getAdicionais',
      userType: 'site',
    };
    api.post(`/site`, parameters).then(async (res) => {
      setAdicionais(res.data.data);
      setIsLoading(false);
    });
    // console.log(res.data);
  }
  useEffect(() => {
    (async () => {
      try {
        AOS.init();

        // const dataScrollContainer = document.querySelector(
        //   '[data-scroll-container]',
        // );
        // if (!dataScrollContainer) {
        //   console.warn(
        //     'locomotive-scroll: [data-scroll-container] dataset was not found. You likely forgot to add it which will prevent Locomotive Scroll to work.',
        //   );
        // }
        // const scroller = new LocomotiveScroll({
        //   el: document.querySelector('[data-scroll-container]'),
        //   smooth: true,
        //   multiplier: 1,
        // });

        // // new ResizeObserver(() => scroller.update()).observe(
        // //   document.querySelector('[data-scroll-container]'),
        // // );
        // return () => {
        //   scroller.destroy();
        // };
      } catch (error) {
        console.log('errooo', error);
      }
    })();
  }, []);

  // function Modal() {
  //   const handleClick = (e) => {
  //     if (e.target.classList.contains('backdrop')) {
  //       setSelectedImg(null);
  //     }
  //   };
  //   const openInsta = (e) => {
  //     window.open('https://www.instagram.com/tropzzoficial/', '_blank');
  //   };

  //   return (
  //     <motion.div
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       className="backdrop"
  //       onClick={(e) => handleClick(e)}
  //     >
  //       <motion.img
  //         initial={{ y: '-100vh' }}
  //         animate={{ y: 0 }}
  //         src={selectedImg}
  //         alt="enlarged pic"
  //         style={{ backgroundColor: '#fff', cursor: 'pointer' }}
  //         onClick={openInsta}
  //       />
  //     </motion.div>
  //   );
  // }

  return (
    <>
      {loadingState ? (
        <Loading />
      ) : (
        <>
          <Navbar />
          <div data-scroll-container>
            <CarouselFirst />
            <section className="section-1" id="section-1" data-scroll-section>
              <div className="container ">
                <div className="col-12 d-md-flex">
                  <div className="col-12 col-md-6 m-auto text-left tropical aos-init aos-animate ">
                    <div>
                      <Title
                        data={` Tropical
                        <br /> <span>é ser</span> você`}
                      />

                      <p data-aos="fade-down">{texts && texts.section1.text}</p>
                    </div>
                    <div className="col-12">
                      <a href="/a-tropzz">
                        <div
                          type="button"
                          className="about-us col-6 animation-hover"
                          data-scroll
                          data-scroll-speed="1"
                          data-scroll-position="top"
                          data-scroll-direction="vertical"
                          data-scroll-repeat="true"
                        >
                          <span className="letter-spacing">SOBRE NÓS</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  p-md-2 d-md-flex d-lg-flex d-xl-flex d-none ">
                    <img
                      className="image-coqueiro image-coqueiro2"
                      src={coqueiro2}
                      alt="coqueiro"
                      width="100%"
                      data-aos="fade-up"
                      data-aos-easing="linear"
                      data-aos-duration="2000"
                    />
                    <img
                      className="image-coqueiro"
                      src={coqueiro1}
                      alt="coqueiro"
                      width="100%"
                      data-aos="zoom-in-up"
                      // data-aos-easing="linear"
                      data-aos-duration="2000"
                    />
                    <img
                      className="image-coqueiro estrelas"
                      src={coqueiro3}
                      alt="coqueiro"
                      width="100%"
                      data-aos="fade-down"
                      data-aos-duration="2000"
                    />
                  </div>
                </div>
              </div>
              <div class="ocean">
                <div
                  class="wave"
                  data-scroll
                  data-scroll-speed="2"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                ></div>
              </div>
            </section>

            <section
              className="section-2 background-purple pb-5 "
              data-scroll-section
            >
              <div class=" ">
                <div class="wrapper">
                  <div class="marquee">
                    <h1
                      class="color-none tropicalidades"
                      data-scroll
                      data-scroll-speed="7"
                      data-scroll-position="top"
                      data-scroll-direction="horizontal"
                    >
                      tropicalidades *{' '}
                    </h1>
                    <h1
                      class="color-none tropicalidades"
                      data-scroll
                      data-scroll-speed="7"
                      data-scroll-position="top"
                      data-scroll-direction="horizontal"
                    >
                      tropicalidades *{' '}
                    </h1>
                    <h1
                      class="color-none tropicalidades"
                      data-scroll
                      data-scroll-speed="7"
                      data-scroll-position="top"
                      data-scroll-direction="horizontal"
                    >
                      tropicalidades *{' '}
                    </h1>
                  </div>
                </div>
              </div>
              <div>
                <div className=" py-5 my-5">
                  <Carousel
                    activeIndex={index}
                    onSelect={(e) => handleSelect(e)}
                    fade
                    indicators={true}
                    buttons={true}
                    nextIcon={
                      <div className="button-content-tropicalidades">
                        <ArrowRight width={30} />
                      </div>
                    }
                    prevIcon={
                      <div className="button-content-tropicalidades">
                        <ArrowLeft width={30} />
                      </div>
                    }
                  >
                    {adicionais &&
                      adicionais.questions &&
                      adicionais.questions.map((adicional, index) => {
                        return (
                          <Carousel.Item>
                            <div className="container d-md-flex px-5 ">
                              <div className="col-12 col-md-5 image-slider ">
                                <img
                                  className="d-block w-100 image-carousel-home"
                                  key={index}
                                  src={adicional.urladicional}
                                  alt={adicional.titleAdicional}
                                />
                              </div>
                              <div
                                className="col-12 col-md-7 m-auto text-slide tex-slide-tropicalidades"
                                data-aos="fade-up"
                                data-aos-easing="linear"
                                data-aos-duration="2000"
                              >
                                <h1>{adicional.titleAdicional}</h1>
                                <div>
                                  <span>{adicional.subtitleAdicional}</span>
                                </div>
                                <br />
                                <div>
                                  <span>{adicional.textAdcional}</span>
                                </div>
                              </div>
                            </div>
                          </Carousel.Item>
                        );
                      })}
                  </Carousel>
                </div>
                <div className="container d-md-flex d-none  px-5">
                  {adicionais &&
                    adicionais.questions &&
                    adicionais.questions.map((adicional, i) => {
                      return (
                        <>
                          <div
                            type="button "
                            className={`${
                              i === index ? 'button-a-tropzz' : ''
                            } button-carrosel`}
                            onClick={(e) => handleSelect(i, e)}
                          >
                            <span>{adicional.titleAdicional}</span>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </section>
            <Franquias params={texts} data-scroll-section />
            <section className="segue-noix" data-scroll-section>
              <div className="container  d-md-flex d-lg-flex ">
                <div className="col-12 col-md-12 in-city ">
                  <div className="segue-insta">
                    <h1>Segue nóixx no insta!</h1>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-5" data-scroll-section>
              <div className="img-grid">
                {instagram &&
                  instagram.map((img, index) => {
                    return (
                      <div
                        className="img-wrap animation-hover-section-instagram"
                        key={index}
                        onClick={() =>
                          window.open('https://instagram.com/tropzzoficial')
                        }
                      >
                        <img
                          src={img}
                          alt="uploadded pic"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 1 }}
                          data-aos="fade-up"
                          className="animation-hover-instagram"
                          data-aos-duration="2000"
                        />
                      </div>
                    );
                  })}

                {/* {selectedImg && (
                 <Modal
                   selectedImg={selectedImg}
                   setSelectedImg={setSelectedImg}
                 />
               )} */}
              </div>

              <div class="">
                <div class="wrapper">
                  <div class="marquee">
                    <h1
                      data-scroll
                      data-scroll-speed="6"
                      data-scroll-position="top"
                      data-scroll-direction="horizontal"
                      class="color-none chega-mais"
                    >
                      chega mais*
                    </h1>
                    <h1
                      class="color-none chega-mais"
                      data-scroll
                      data-scroll-speed="6"
                      data-scroll-position="top"
                      data-scroll-direction="horizontal"
                    >
                      {' '}
                      chega mais*
                    </h1>
                    <h1
                      class="color-none chega-mais"
                      data-scroll
                      data-scroll-speed="6"
                      data-scroll-position="top"
                      data-scroll-direction="horizontal"
                    >
                      {' '}
                      chega mais*
                    </h1>
                  </div>
                </div>
              </div>
            </section>
            <div className="address d-flex" id="address">
              <div className="col-12 col-md-4">
                <AddressInput data-scroll-section instagram={instagram} />
              </div>
              {/* <div className="col-12 col-md-6">
                 <img src={address} alt="" />
               </div> */}
            </div>
            <div class="ocean wave-onde-ce-mora">
              <div
                class="wave-white"
                data-scroll
                data-scroll-speed="3"
                data-scroll-position="top"
                data-scroll-direction="horizontal"
              ></div>
            </div>
            <section>
              <div className="container  d-md-flex   contatinho">
                <div className="container col-md-6 col-12 mx-auto mt-md-5">
                  <h1
                    className="title-contact"
                    data-aos="fade-down-right"
                    data-aos-duration="2000"
                  >
                    contatinho
                  </h1>
                  <div
                    className="contact font-size-20"
                    data-aos="fade-up-left"
                    data-aos-duration="2000"
                  >
                    <p>
                      (37) 9 9172 4297 <br /> contato@tropzz.com.br
                    </p>
                    <p>
                      Rua Professor Jacinto Ribeiro, 242 Lagoa da Prata, MG,
                      Brasil CEP 35590-000
                    </p>
                    <p>
                      Horário de Funcionamento <br /> Todos os dias 10:00 -
                      22:00
                    </p>
                    <p>Conecte-se com a Tropzz</p>
                    <div className="d-flex pb-5">
                      <div className="img px-md-1 icon-social-contatinho ">
                        <a
                          href="https://www.facebook.com/tropzzoficial"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Facebook />
                        </a>
                      </div>
                      <div className="img px-md-1 icon-social-contatinho ">
                        <a
                          href="https://www.instagram.com/tropzzoficial"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Instagram />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 m-auto">
                  <Form />
                </div>
              </div>
              <div class="ocean">
                <div
                  class="wave"
                  data-scroll
                  data-scroll-speed="3"
                  data-scroll-position="top"
                  data-scroll-direction="horizontal"
                ></div>
              </div>
            </section>

            <Footer data-scroll data-scroll-section />
          </div>
          {getCookieConsentValue() !== 'true' && (
            <CookieConsent
              location="bottom"
              buttonText="ACEITAR"
              buttonId="confirm-button-cookie"
              style={{
                background: '#4C2C89',
                color: '#fff',
                width: 'initial',
                justifyContent: 'center',
                display: 'flex',
                padding: '0px 8rem',
                bottom: '0',
                width: '-webkit-fill-available',
                alignItems: 'center',
              }}
              buttonStyle={{
                color: '#A090BF',
                backgroundColor: '#E9E0EE',
                fontFamily: 'Circular Medium',
                borderRadius: '50px',
                margin: '15px',
                padding: '0.5rem 1rem',
              }}
              expires={30}
            >
              <div
                className="content-cookie-acept"
                onClick={() => {
                  document.querySelector('#confirm-button-cookie').click();
                }}
              >
                Nós armazenamos dados temporariamente para melhorar a sua
                experiência de navegação e recomendas conteúdo de seu interesse.
                Ao utilizar nossos serviços, você concorda com tal
                monitoramento.
                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="collapsed list-unstyled"
                  style={{ color: '#fff', fontFamily: 'Circular Black' }}
                >
                  Política de Privacidade.
                </a>
              </div>
            </CookieConsent>
          )}
        </>
      )}
    </>
  );
}
